const MetricCard = ({ metric, details, children }) => (
  <div className="rounded-lg shadow-sm mb-4">
    <div className="rounded-lg border-gray-600 shadow-lg min-h-full relative overflow-hidden">
      <div className="px-1 lg:px-2 xl:px-4 pt-8 pb-10 text-center relative">
          <h2 className="text-3xl lg:text-5xl font-bold leading-tight my-3">{metric}</h2>
          <h3 className="text-2xl lg:text-3xl font-bold leading-tight my-3">{details}</h3>
        { children && children }
      </div>
    </div>
  </div>
);

export default MetricCard;
