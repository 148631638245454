import AnchorLink from 'react-anchor-link-smooth-scroll';
import SvgArrow from '../images/icons/down-arrow.svg';
import React from 'react';

class ResponsiveArrow extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {}
  }

  // For whatever reason the onScroll, probably due to AnchorLink's synthetic-event is not being detected, hence we're doing it live
  // with good ol javascript
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
    const scrollTop = this.myRef.current ? this.myRef.current.offsetTop : 0;

    // returns a percentage of opacity, with initial value being on
    let opacity = ((scrollTop - scrollY - (scrollY * .9))/ scrollTop)

    this.setState({
      opacity
    })
  }

  render() {

    return (
      <div
        onScroll={this.handleScroll}
        ref={this.myRef}
        style={{opacity: this.state.opacity}}
      >
        <AnchorLink
          href={this.props.scrollTo}
        >
          <span>
            <img className="opacity-1 responsive-arrow-img object-scale-down w-full mt-10 lg:mt-24 xl:mt-8 xxl:mt-24" src={SvgArrow}/>
          </span>
        </AnchorLink>
      </div>
    )
  }
}

export default ResponsiveArrow;

