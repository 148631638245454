import React from 'react';
import { Link } from 'gatsby'
import TabTitle from '../components/meta/tabTitle';
import Button from '../components/Button';
import Layout from '../components/layout/Layout';
import MetricCard from '../components/MetricsCard';
import ResponsiveArrow from '../components/ResponsiveArrow';
// svg icons from here https://heroicons.dev/

const CompanyPage = () => {
  return (
    <Layout>
      <TabTitle title="Company" />
      <section className="pt-6 xl:py-10 xl:pt-32 xl:pb-20 xxl:pb-20 container mx-auto flex justify-end font-bold">
        <h2 className="xl:w-2/3 wide:w-1/2 xl:pl-8 company-page-splash-text">DiFi enables customized financial solutions—anytime, anywhere, for anyone.</h2>
      </section>
      <ResponsiveArrow scrollTo="#we-power-block" />
      <div className="py-12 lg:mb-6 bg-splashblue text-white" id="we-power-block">
      <section className="container mx-auto justify-start text-2xl">
        <div className="py-10">
          <div className="font-normal text-3xl" >We power next generation financial services that:</div>
          <ol className="text-2xl font-thin">
            <li className="pl-1">(I) Reflect capital owners’ needs.</li>
            <li className="pl-1">(II) Integrate data and capabilities from any financial transaction, service or system.</li>
            <li className="pl-1">(III) Implement and syndicate rapidly, at the lowest CAPEX and unit economics.</li>
          </ol>
        </div>
      </section>

        <section className="container mx-auto flex justify-start">
          <div>
            <Link to="/#model"><button className="pill-button">Accounting Book of Record</button></Link>
            <Link to="/analytics"><button className="pill-button">Analytics</button></Link>
          </div>
        </section>

    </div>
      <section className="container mx-auto my-8">
        <div className="markdown">
        <h2>Key Facts & Figures</h2>
        </div>
        <div className="h-full grid mobileVertical:grid-rows-3 lg:grid-rows-2 sm:grid-flow-col gap-4">
          <MetricCard metric="99.999%" details="data accuracy">

          </MetricCard>
          <MetricCard metric="$2T" details="assets on platform ">
          </MetricCard>
          <MetricCard metric="20+" details="years expertise">
          </MetricCard>
          <MetricCard metric="1.3M" details="investor portfolios ">
          </MetricCard>
          <MetricCard metric="$5T" details="monthly trade volume">
          </MetricCard>
          <MetricCard metric="1.5M+" details="securities">
          </MetricCard>
        </div>
      </section>
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">Ready to build the future of financial services?</h3>
        <p className="mt-8 text-xl font-light">
        </p>

        <p className="mt-8">
          <Link to="/contact">
            <Button size="xl">Get In Touch to Learn More</Button>
          </Link>
        </p>
      </section>
    </Layout>
  );
};

export default CompanyPage;
